interface Result {
  bogon: boolean;
  country: string;
}

export const getOrFindCountry = async (): Promise<string | undefined> => {
  return getDefaultCountry();
};

const getDefaultCountry = (): string | undefined => {
  return 'NZ';
  // TODO: const locale = getLocale();
  // const parts = locale.split('-');
  // if (parts.length !== 2) return undefined;
  // return parts[1];
};

export const getCountry = (): string | undefined => {
  return localStorage.getItem('country') ?? getDefaultCountry();
};
