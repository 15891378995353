import { Statuses, Time } from '@geee-be/core';
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  setContext,
  setUser,
} from '@sentry/react';
import { isAxiosError, isCancel } from 'axios';
import type { Person, TokenResponse } from 'core';
import flagsmith from 'flagsmith';
import type { User } from 'oidc-client-ts';
import type { FC, PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useOidc } from '../oidc/oidc-context/use-oidc.js';
import { formatName } from '../utils/format.js';
import type { Auth, AuthenticatedState, AuthenticationState } from './types.js';
import { AuthStatus } from './types.js';
import {
  INITIAL_AUTHENTICATION_STATE,
  authorizeToken,
  getProfile,
  invalidateToken,
  refreshToken,
} from './utils.js';

const authContext = createContext<Auth>({
  refresh: async () => Promise.reject(new Error('Not configured')),
  signIn: async () => Promise.reject(new Error('Not configured')),
  signOut: async () => Promise.reject(new Error('Not configured')),
  state: undefined,
});

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): Auth => {
  return useContext(authContext);
};

// Hook for child components to get the auth object throwing exception if not authenticated ...
// ... and re-render when it changes.
export const useAuthenticated = (): AuthenticatedState => {
  const context = useContext(authContext);
  if (context.state?.status !== AuthStatus.AUTHENTICATED)
    throw new Error('Not authenticated');
  return context.state;
};

const getTraits = (
  person: Person.Type,
): Record<string, string | number | boolean | null> => {
  const traits: Record<string, string | number | boolean | null> = {};

  person.roles?.forEach((role) => {
    traits[`role_${role}`] = true;
  });
  person.tags?.forEach((tag) => {
    traits[`tag_${tag}`] = true;
  });
  if (person.labels) {
    const { labels } = person;
    Object.keys(labels).forEach((label) => {
      traits[`label_${label}`] = labels[label];
    });
  }

  return traits;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<AuthenticationState>(
    INITIAL_AUTHENTICATION_STATE,
  );
  const oidc = useOidc();
  const protection = useRef(false); // HACK

  const handleTokenUpdate = useCallback(
    async (
      response?: TokenResponse,
      controller?: AbortController,
    ): Promise<void> => {
      if (response?.success) {
        const person = await getProfile(controller);
        if (person) {
          return setState({
            status: AuthStatus.AUTHENTICATED,
            accessExpires: response.accessExpires,
            refreshExpires: response.refreshExpires,
            person,
            timestamp: Date.now(),
            loading: false,
          });
        }
      }

      return setState({
        status: AuthStatus.NOT_AUTHENTICATED,
        loading: false,
      });
    },
    [],
  );

  const refresh = useCallback(
    async (controller?: AbortController): Promise<void> => {
      try {
        await handleTokenUpdate(await refreshToken(controller), controller);
      } catch (err) {
        if (isCancel(err)) return;

        if (
          isAxiosError(err) &&
          err.response?.status === Statuses.UNAUTHORIZED
        ) {
          addBreadcrumb({ message: 'Refresh - not authorized' });
          return setState({
            status: AuthStatus.NOT_AUTHENTICATED,
            loading: false,
          });
        }

        if (isAxiosError(err)) {
          if (err.code === 'ERR_NETWORK') {
            addBreadcrumb({ message: 'Refresh - network error' });
          } else if (err.status !== Statuses.FORBIDDEN) {
            captureMessage('Token refresh error', {
              level: 'error',
              extra: { err },
            });
          }
        } else {
          captureMessage('Token refresh (non-axios) error', {
            level: 'error',
            extra: { err },
          });
        }
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    },
    [handleTokenUpdate],
  );

  const signIn = (provider: string): Promise<void> => {
    localStorage.setItem('entrypoint', window.location.hash);
    return oidc.signinRedirect(provider);
  };

  const oidcUserLoaded = useCallback(
    async (user: User): Promise<void> => {
      protection.current = true;
      try {
        const idToken = user.id_token;
        if (!idToken) {
          addBreadcrumb({ message: 'No id_token' });
          setState({
            status: AuthStatus.NOT_AUTHENTICATED,
            loading: false,
          });
          return;
        }

        setState((prev) => ({
          ...prev,
          loading: true,
        }));

        await handleTokenUpdate(await authorizeToken(idToken));

        const entrypoint = localStorage.getItem('entrypoint') ?? '#/';
        localStorage.removeItem('entrypoint');
        if (entrypoint !== '#/') {
          window.location.href = entrypoint;
        }

        protection.current = false;
      } catch (err) {
        protection.current = false;
        if (isCancel(err)) return;

        if (isAxiosError(err) && err.status === Statuses.UNAUTHORIZED) {
          captureMessage('OIDC - not authorized');
          setState({
            status: AuthStatus.NOT_AUTHENTICATED,
            loading: false,
          });
          return;
        }

        captureException(err);
        setState((prev) => ({
          ...prev,
          error: err as Error,
        }));
        return;
      }
    },
    [handleTokenUpdate],
  );

  const signOut = async (controller?: AbortController): Promise<void> => {
    addBreadcrumb({ message: 'Sign Out' });

    setState({
      status: AuthStatus.NOT_AUTHENTICATED,
      loading: true,
    });
    await invalidateToken(controller).catch(() => {
      console.log('Already signed out');
    });
    setState({
      status: AuthStatus.NOT_AUTHENTICATED,
      loading: false,
    });
  };

  useEffect(() => {
    if (state.status !== AuthStatus.INITIALIZING) return;
    if (oidc.activeNavigator || oidc.isLoading || protection.current) return;

    // Refresh token on initial page load
    const controller = new AbortController();
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    refresh(controller).catch((err: Error) => {
      setState((prev) => ({
        ...prev,
        error: err,
      }));
    });
    return () => controller.abort();
  }, [state.status, oidc.isLoading, oidc.activeNavigator, refresh]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: it's included - problem with casting
  useEffect(() => {
    if (state.status !== AuthStatus.AUTHENTICATED) {
      setContext('person', {});
      setUser(null);
      flagsmith.identify('anonymous').catch(captureException);
      return;
    }

    const person = state.person;
    const common = {
      email: person.email,
      name: formatName(person),
      organizationId: person.organizationId,
    };
    setContext('person', {
      id: person._id,
      ...common,
    });
    setUser({
      id: person._id,
      email: person.email,
      name: formatName(person),
    });

    flagsmith.identify(person.email, getTraits(person)).catch(captureException);

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (window as any)?.hj('identify', person._id, common);
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (window as any)?.clarity?.('identify', person._id);
  }, [state.status, (state as AuthenticatedState).person]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: casting problem
  useEffect(() => {
    if (state.status !== AuthStatus.AUTHENTICATED) return;

    const delay =
      state.accessExpires.getTime() - (Date.now() + Time.minutes(1));
    if (Number.isNaN(delay) || delay >= 2147483647) return;

    const handle = setTimeout(() => {
      addBreadcrumb({
        data: { state },
        level: 'debug',
        message: 'Attempting to refresh access token',
      });
      refresh().catch(captureException);
    }, delay);

    return () => clearTimeout(handle);
  }, [state, (state as AuthenticatedState).accessExpires, refresh]);

  useEffect(() => {
    const handleVisibilityChange = (): void => {
      if (document.visibilityState !== 'visible') return;

      console.log('Returning to tab', state);

      if (
        state.status === AuthStatus.AUTHENTICATED &&
        state.refreshExpires.getTime() < Date.now()
      ) {
        console.log('Refresh token has expired');
        setState({
          status: AuthStatus.NOT_AUTHENTICATED,
          loading: true,
        });
        return;
      }

      if (
        state.status === AuthStatus.AUTHENTICATED &&
        state.accessExpires.getTime() < Date.now() + Time.minutes(5)
      ) {
        console.log('Refreshing access token on return to page');
        refresh().catch(captureException);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [state, refresh]);

  useEffect(
    () => oidc.events?.addUserLoaded(oidcUserLoaded),
    [oidc.events, oidcUserLoaded],
  );

  return (
    <authContext.Provider
      value={{
        refresh,
        signIn,
        signOut,
        state,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
