import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react.js';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client'; // eslint-disable-line import/extensions
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import './main.css';
import { CacheProvider } from './providers/cache.js';
import { Site } from './site.jsx';
import './utils/axios.js';

const MATCH_FEATURE_FLAG = /flagsmith|hotjar/;

Sentry.init({
  dsn: 'https://bd57cc43735248ed814f01fb8f3b8699@o4504547380232192.ingest.sentry.io/4504943818899456',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
      tracePropagationTargets: ['otrax.app'],
      shouldCreateSpanForRequest: (url) => {
        return !url.match(MATCH_FEATURE_FLAG);
      },
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],

  denyUrls: [MATCH_FEATURE_FLAG],

  beforeBreadcrumb: (breadcrumb, hint) => {
    const xhr = hint?.xhr as XMLHttpRequest | undefined;
    return breadcrumb.category === 'xhr' &&
      xhr?.responseURL.match(MATCH_FEATURE_FLAG)
      ? null
      : breadcrumb;
  },

  environment: 'browser',
  ignoreErrors: ['AbortError', 'CanceledError', 'UnauthorizedError'],

  release: import.meta.env.VITE_GIT_SHA as string,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.5,
});

Sentry.setExtras({
  navigatorLanguage: window.navigator.language,
  navigatorLanguages: window.navigator.languages,
  intlLocale: new Intl.DateTimeFormat().resolvedOptions().locale,
});

const options = Sentry.getCurrentHub()?.getClient()?.getOptions();
if (options) options.enabled = window.location.hostname !== 'localhost';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <FlagsmithProvider
      options={{
        environmentID: String(import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID),
      }}
      flagsmith={flagsmith}
    >
      <CacheProvider>
        <Site />
      </CacheProvider>
    </FlagsmithProvider>
  </StrictMode>,
);
