import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';
import type { Props } from './types.js';

export const Spinner: FC<Props> = ({ size }) => {
  return <CircularProgress size={size} sx={{ m: 3 }} />;
};

export const HorizontalMiddleSpinner: FC<Props> = ({ size }) => {
  return (
    <Box
      sx={{
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        justifySelf: 'center',
      }}
    >
      <Spinner size={size} />
    </Box>
  );
};

export const MiddleSpinner: FC<Props> = ({ size }) => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size={size} />
    </Box>
  );
};
