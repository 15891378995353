import type { UserManagerSettings } from 'oidc-client-ts';

// cspell: disable

export const providers: Record<string, UserManagerSettings> = {
  auth0: {
    authority: 'https://otrax-app.au.auth0.com',
    // eslint-disable-next-line camelcase
    client_id: import.meta.env.VITE_AUTH_AUTH0_CLIENT_ID as string,
    // eslint-disable-next-line camelcase
    client_secret: import.meta.env.VITE_AUTH_AUTH0_CLIENT_SECRET as string,
    // eslint-disable-next-line camelcase
    redirect_uri: `${window.location.origin}/#/`,
    // ...
    scope: 'openid email',
  },
  facebook: {
    authority: 'https://www.facebook.com',
    metadata: {
      issuer: 'https://www.facebook.com',
      // eslint-disable-next-line camelcase
      authorization_endpoint: 'https://facebook.com/v11.0/dialog/oauth/',
      // eslint-disable-next-line camelcase
      jwks_uri: 'https://www.facebook.com/.well-known/oauth/openid/jwks/',
      // eslint-disable-next-line camelcase
      token_endpoint: 'https://graph.facebook.com/v11.0/oauth/access_token',
      // eslint-disable-next-line camelcase
      response_types_supported: ['id_token', 'token id_token'],
      // eslint-disable-next-line camelcase
      subject_types_supported: ['pairwise'],
      // eslint-disable-next-line camelcase
      token_endpoint_auth_signing_alg_values_supported: ['RS256'],
      // id_token_signing_alg_values_supported: ['RS256'],
      // eslint-disable-next-line camelcase
      claims_supported: [
        'iss',
        'aud',
        'sub',
        'iat',
        'exp',
        'jti',
        'nonce',
        'at_hash',
        'name',
        'given_name',
        'middle_name',
        'family_name',
        'email',
        'picture',
        'user_friends',
        'user_birthday',
        'user_age_range',
        'user_link',
        'user_hometown',
        'user_location',
        'user_gender',
      ],
      // userinfo_endpoint: 'https://www.facebook.com',
      // eslint-disable-next-line camelcase
      // end_session_endpoint: 'https://www.facebook.com',
    },
    // eslint-disable-next-line camelcase
    client_id: import.meta.env.VITE_AUTH_FACEBOOK_CLIENT_ID as string,
    // eslint-disable-next-line camelcase
    client_secret: import.meta.env.VITE_AUTH_FACEBOOK_CLIENT_SECRET as string,
    // eslint-disable-next-line camelcase
    redirect_uri: `${window.location.origin}/#/`,
    // ...
    scope: 'openid email',
  },
  google: {
    authority: 'https://accounts.google.com',
    // eslint-disable-next-line camelcase
    client_id: import.meta.env.VITE_AUTH_GOOGLE_CLIENT_ID as string,
    // eslint-disable-next-line camelcase
    client_secret: import.meta.env.VITE_AUTH_GOOGLE_CLIENT_SECRET as string,
    // eslint-disable-next-line camelcase
    redirect_uri: `${window.location.origin}/`,
    // ...
    scope: 'openid email profile',
  },
};

// cspell: enable
