import { Public as CountryIcon } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';
import type { FC } from 'react';
import { useCountry } from '../../providers/country.js';

export const CountryMenu: FC = () => {
  const country = useCountry();

  return (
    <>
      <ListItemIcon>
        <CountryIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{country ?? 'unknown'}</ListItemText>
    </>
  );
};
