import { useFlags } from 'flagsmith/react.js';

export const useBoolFlag = (flag: string): boolean => {
  const flags = useFlags([flag]);
  const value = flags[flag];
  return !!value?.enabled || import.meta.env.DEV;
};

export const useNumberFlag = (flag: string, defaultValue: number): number => {
  const flags = useFlags([flag]);
  const value = flags[flag];
  const numValue = Number(value.value);
  return value?.enabled
    ? !Number.isNaN(numValue)
      ? numValue
      : defaultValue
    : defaultValue;
};
