import { Refresh as RefreshIcon } from '@mui/icons-material';
import { Button, CardContent, Grid, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { LottiePlayer } from '../lottie/player.js';
import { CenterCard } from '../page-layout/center-card.js';

interface Props {
  onTryAgainClick: () => void;
}

export const NetworkErrorView: FC<Props> = ({ onTryAgainClick }) => (
  <CenterCard sx={{ my: 6, height: '100%' }}>
    <CardContent>
      <Grid container spacing={6}>
        <Grid item xs={12} md={3}>
          <LottiePlayer src="https://assets4.lottiefiles.com/packages/lf20_oli9wvof.json" />
        </Grid>
        <Grid item xs={12} md={9}>
          <Stack spacing={3}>
            <Typography variant="h3">Network Error</Typography>
            <Typography>
              A network error occurred while communicating with the server.
            </Typography>
            <Button
              onClick={onTryAgainClick}
              variant="contained"
              sx={{ width: 'fit-content' }}
              startIcon={<RefreshIcon />}
            >
              Refresh to try again
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </CardContent>
  </CenterCard>
);
// https://assets4.lottiefiles.com/packages/lf20_oli9wvof.json
