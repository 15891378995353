import {
  ConfirmationNumber as EventIcon,
  Route as FeedIcon,
  Groups as GroupIcon,
  Ballot as SeriesIcon,
} from '@mui/icons-material';
import type { MenuItemProps, Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { captureException, captureMessage } from '@sentry/react';
import type { ComponentType, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth, useAuthenticated } from '../auth/auth-provider.js';
import { useBoolFlag } from '../hooks/use-flag.js';
import { SiteLayoutView } from './site-layout.jsx';
import type { NavMenuItemProps } from './types.js';
import { CountryMenu } from './user-menu/country.js';
import { SignOutMenu } from './user-menu/sign-out.js';
import { TestMenu } from './user-menu/test.js';

const controller =
  (View: typeof SiteLayoutView): ComponentType =>
  () => {
    const { signOut } = useAuth();
    const { person } = useAuthenticated();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const location = useLocation();
    const narrowScreen = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm'),
    );
    const test = useBoolFlag('test');

    useEffect(() => {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (window as any)?.clarity?.('identify', person._id);
    }, [person]);

    useEffect(() => {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (window as any).clarity?.('set', 'page', location.hash);
    }, [location]);

    const showBackButton = location.pathname !== '/events';

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>): void => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (): void => {
      setAnchorElUser(null);
    };

    const handleBack = (): void => {
      window.history.go(-1);
    };

    const navMenuItems: NavMenuItemProps[] = [
      {
        children: 'Events',
        href: '/events',
        icon: EventIcon,
      },
      {
        children: 'My Tracks',
        href: '/tracks',
        icon: FeedIcon,
      },
    ];

    if (useBoolFlag('series')) {
      navMenuItems.push({
        children: 'Series',
        href: '/series',
        icon: SeriesIcon,
      });
    }

    if (useBoolFlag('groups')) {
      navMenuItems.push({
        children: 'Groups',
        href: '/groups',
        icon: GroupIcon,
      });
    }

    const userMenuItems: MenuItemProps[] = [
      {
        children: CountryMenu({}),
        disabled: true,
      },
      {
        children: SignOutMenu({}),
        onClick: () => {
          signOut().catch(captureException);
          handleCloseUserMenu();
        },
      },
    ];

    if (test) {
      userMenuItems.unshift({
        children: TestMenu({}),
        onClick: () => {
          captureMessage('Locale', {
            extra: {
              language: window.navigator.language,
              locale: new Intl.DateTimeFormat().resolvedOptions().locale,
            },
          });
          console.log('Locale', {
            language: window.navigator.language,
            locale: new Intl.DateTimeFormat().resolvedOptions().locale,
          });
          handleCloseUserMenu();
        },
      });
    }

    return View({
      anchorElUser,
      handleBack,
      handleOpenUserMenu,
      handleNavMenuClick: (href: string) => {
        navigate(href);
      },
      handleCloseUserMenu,
      navMenuItems,
      profile: person,
      showBackButton,
      smallScreen: narrowScreen,
      userMenuItems,
    });
  };

export default controller(SiteLayoutView);
