import type { SxProps } from '@mui/material';
import { Card, Container } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

interface Props {
  sx?: SxProps;
}

export const CenterLayout: FC<PropsWithChildren<Props>> = ({
  children,
  sx,
}) => (
  <Container maxWidth="md" sx={{ px: 0, ...sx }}>
    {children}
  </Container>
);

export const PageCard: FC<PropsWithChildren<Props>> = ({ children, sx }) => (
  <Card elevation={4} sx={sx}>
    {children}
  </Card>
);

export const CenterCard: FC<PropsWithChildren<Props>> = ({ children, sx }) => (
  <CenterLayout sx={sx}>
    <PageCard>{children}</PageCard>
  </CenterLayout>
);
