import axios from 'axios';
import type { Person, TokenResponse } from 'core';
import type { InitialAuthenticationState } from './types.js';
import { AuthStatus } from './types.js';

export const INITIAL_AUTHENTICATION_STATE: InitialAuthenticationState = {
  status: AuthStatus.INITIALIZING,
  loading: false,
};

export const authorizeToken = (
  idToken: string,
  controller?: AbortController,
): Promise<TokenResponse | undefined> =>
  axios
    .post<TokenResponse>('/api/v1/token/authorize', undefined, {
      headers: { Authorization: `Bearer ${idToken}` },
      signal: controller?.signal,
    })
    .then((response) => response.data);

export const refreshToken = (
  controller?: AbortController,
): Promise<TokenResponse | undefined> =>
  axios
    .post<TokenResponse>('/api/v1/token/refresh', undefined, {
      signal: controller?.signal,
    })
    .then((response) => response.data);

export const invalidateToken = (
  controller?: AbortController,
): Promise<TokenResponse | undefined> =>
  axios
    .post<TokenResponse>('/api/v1/token/invalidate', undefined, {
      signal: controller?.signal,
    })
    .then((response) => response.data);

export const getProfile = (
  controller?: AbortController,
): Promise<Person.Type | undefined> =>
  axios
    .get<Person.Type>('/api/v1/my/profile', { signal: controller?.signal })
    .then((response) => response.data);
