import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import usePromise from 'react-use-promise';
import { MiddleSpinner } from '../components/spinner/spinner.js';
import { getOrFindCountry } from '../utils/country.js';

const countryContext = createContext<string | undefined>(undefined);

export const CountryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [country, , status] = usePromise(getOrFindCountry(), []);

  if (status === 'pending') return <MiddleSpinner />;

  return (
    <countryContext.Provider value={country}>
      {children}
    </countryContext.Provider>
  );
};

export const useCountry = (): string | undefined => useContext(countryContext);
