import { Box } from '@mui/material';
import lottie from 'lottie-web';
import type { CSSProperties, FC } from 'react';
import { createRef, useEffect } from 'react';

interface PlayerProps {
  className?: string;
  src: string;
  sx?: CSSProperties;
}

export const LottiePlayer: FC<PlayerProps> = ({ className, src, sx }) => {
  const container = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!container.current) return;

    const instance = lottie.loadAnimation({
      autoplay: true,
      container: container.current,
      loop: true,
      path: src,
      renderer: 'svg',
    });

    return () => instance.destroy();
  }, [container, src]);

  return (
    <Box
      sx={sx}
      id="lottie"
      className={className}
      ref={container}
      style={{
        margin: '0 auto',
        outline: 'none',
        overflow: 'hidden',
      }}
    />
  );
};
