import { captureException } from '@sentry/react';
import { isAxiosError } from 'axios';
import type { FC, PropsWithChildren } from 'react';
import { useAuth } from '../../auth/auth-provider.js';
import { AuthStatus } from '../../auth/types.js';
import { ErrorPage } from '../../pages/error/view.js';
import { MiddleSpinner } from '../spinner/spinner.js';
import { NetworkErrorView } from './network-error.js';
import type { RequireAuthProps } from './types.js';

export const RequireAuth: FC<PropsWithChildren<RequireAuthProps>> = ({
  children,
  fallback,
}) => {
  const auth = useAuth();

  if (!auth.state || auth.state?.loading) {
    return <MiddleSpinner />;
  }

  if (auth.state.error) {
    captureException(auth.state.error);
    if (
      isAxiosError(auth.state.error) &&
      auth.state.error.code === 'ERR_NETWORK'
    ) {
      return <NetworkErrorView onTryAgainClick={() => location.reload()} />;
    }
    return <ErrorPage error={auth.state.error} />;
  }

  if (
    [AuthStatus.INITIALIZING, AuthStatus.INITIALIZED].includes(
      auth.state.status,
    )
  ) {
    return <MiddleSpinner />;
  }

  if (auth.state.status !== AuthStatus.AUTHENTICATED) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
