import type { Router as RemixRouter } from '@remix-run/router';
import { captureException, captureMessage } from '@sentry/react';
import type { FC } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, createHashRouter, useRouteError } from 'react-router-dom';
import { useBoolFlag } from './hooks/use-flag.js';
import SiteLayout from './layout/controller.js';
import { CompareCourseTracksPage } from './pages/comapare-course-tracks/page.js';
import { ErrorPage } from './pages/error/view.js';
import { EventControlsPage } from './pages/event-controls/page.js';
import { CreateEventPage } from './pages/event-create/page.js';
import { EventListPage } from './pages/event-list/page.js';
import { EventSeriesListPage } from './pages/event-series-list/page.js';
import { EventSeriesPage } from './pages/event-series/page.js';
import { EventPage } from './pages/event/page.js';
import { FeedPage } from './pages/feed/page.js';
import { GroupListPage } from './pages/group-list/page.js';
import { GroupPage } from './pages/group/page.js';
import { NotFoundPage } from './pages/not-found/page.js';
import { StravaImportListPage } from './pages/strava-import/page.js';
import { TrackUploadPage } from './pages/track-upload/page.js';
import { TrackPage } from './pages/track/page.js';

const ErrorBoundary: FC = () => {
  const error = useRouteError();
  if (error instanceof Error) {
    captureException(error);
  } else {
    captureMessage('Page routing error', { level: 'error' });
  }
  console.error(error);
  return <ErrorPage error={error} />;
};

export const useRouter = (): RemixRouter => {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Navigate to="/events" replace />,
      ErrorBoundary,
    },
    {
      path: '/tracks',
      Component: FeedPage,
      ErrorBoundary,
    },
    {
      path: '/track/upload',
      Component: TrackUploadPage,
      ErrorBoundary,
    },
    {
      path: '/track/:id',
      Component: TrackPage,
      ErrorBoundary,
    },
    {
      path: '/strava/import',
      Component: StravaImportListPage,
      ErrorBoundary,
    },
    {
      path: '/events',
      Component: EventListPage,
      ErrorBoundary,
    },
    {
      path: '/event/:id',
      Component: EventPage,
      ErrorBoundary,
    },
    {
      path: '/event/:id/controls',
      Component: EventControlsPage,
      ErrorBoundary,
    },
    {
      path: '/series',
      Component: EventSeriesListPage,
      ErrorBoundary,
    },
    {
      path: '/series/:countryOrYear/:slug',
      Component: EventSeriesPage,
      ErrorBoundary,
    },
    {
      path: '/event/:eventId/course/:courseId/tracks',
      Component: CompareCourseTracksPage,
      ErrorBoundary,
    },
  ];

  if (useBoolFlag('event.create')) {
    routes.push({
      path: '/events/create',
      Component: CreateEventPage,
      ErrorBoundary,
    });
  }

  if (useBoolFlag('groups')) {
    routes.push({
      path: '/groups',
      Component: GroupListPage,
      ErrorBoundary,
    });
    routes.push({
      path: '/group/:id',
      Component: GroupPage,
      ErrorBoundary,
    });
  }

  // if (useBoolFlag('group.create')) {
  //   routes.push({
  //     path: '/groups/create',
  //     Component: CreateGroupPage,
  //     ErrorBoundary,
  //   });
  // }

  return createHashRouter([
    {
      path: '/',
      element: <SiteLayout />,
      ErrorBoundary,
      children: routes,
    },
    {
      path: '*',
      Component: NotFoundPage,
      ErrorBoundary,
    },
  ]);
};
