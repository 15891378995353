import type { ThemeOptions } from '@mui/material';
import { deepOrange, green, grey } from '@mui/material/colors'; // eslint-disable-line import/extensions

export const baseTheme = (prefersDarkMode: boolean): ThemeOptions => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => `
        html, body {
          color: ${themeParam.palette.text.primary};
          background-color: ${themeParam.palette.background.default};
        }
      `,
    },
    MuiCardActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          justifyContent: 'space-between',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(3),
          paddingBottom: 0,
          width: '100%',
          '&:last-child': {
            paddingBottom: theme.spacing(3),
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(3),
          paddingBottom: 0,
          width: '100%',
          '&:last-child': {
            paddingBottom: theme.spacing(3),
          },
        }),
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '&:last-child .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }),
      },
    },
  },
  palette: {
    mode: prefersDarkMode ? 'dark' : 'light',
    primary: {
      main: deepOrange[500],
    },
    secondary: {
      main: green[500],
    },
    background: {
      default: prefersDarkMode ? '#121212' : grey[50],
    },
  },
  shape: { borderRadius: 6 },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.33rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 600,
    },
  },
});
