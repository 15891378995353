import { captureEvent } from '@sentry/browser';
import type { User, UserManagerSettings } from 'oidc-client-ts';
import { UserManager } from 'oidc-client-ts';
import type { FC, PropsWithChildren } from 'react';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import { OidcContext } from './oidc-context.js';
import { reducer } from './reducer.js';
import type {
  AuthState,
  OidcContextProps,
  OidcProviderProps,
} from './types.js';
import { hasAuthParams, loginError } from './utils.js';

const LOCAL_STORAGE_PROVIDER_KEY = 'oidc-provider' as const;

/**
 * The initial auth state.
 */
const INITIAL_AUTH_STATE: AuthState = {
  isLoading: true,
  isAuthenticated: false,
};

export const OidcProvider: FC<PropsWithChildren<OidcProviderProps>> = ({
  children,
  onSigninCallback,
  skipSigninCallback,
  providers,
}) => {
  const getUserManagerSettings = (provider: string): UserManagerSettings => {
    localStorage.setItem(LOCAL_STORAGE_PROVIDER_KEY, provider);
    const selectedUserManagerSettings = providers[provider];
    if (!selectedUserManagerSettings) {
      throw new Error(`UserManager not found for ${provider}`);
    }
    return selectedUserManagerSettings;
  };

  const signInFunctions = useMemo<
    Pick<OidcContextProps, 'signinPopup' | 'signinRedirect'>
  >(
    () => ({
      signinPopup: (provider, args) => {
        return new UserManager(getUserManagerSettings(provider)).signinPopup(
          args,
        );
      },
      signinRedirect: (provider, args) => {
        return new UserManager(getUserManagerSettings(provider)).signinRedirect(
          args,
        );
      },
    }),
    [getUserManagerSettings],
  );

  const userManagerKey = localStorage.getItem(LOCAL_STORAGE_PROVIDER_KEY);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const userManager = useMemo(() => {
    const userManagerSettings = userManagerKey
      ? providers[userManagerKey]
      : undefined;
    return userManagerSettings
      ? new UserManager(userManagerSettings)
      : undefined;
  }, [providers]);

  const [state, dispatch] = useReducer(reducer, INITIAL_AUTH_STATE);
  const didInitialize = useRef(false);

  useEffect(() => {
    if (!userManager || didInitialize.current) {
      return;
    }
    didInitialize.current = true;

    void (async (): Promise<void> => {
      let user: User | null = null;
      try {
        // check if returning back from authority server
        if (hasAuthParams() && !skipSigninCallback) {
          user = (await userManager.signinCallback()) ?? null;
          onSigninCallback && user && (await onSigninCallback(user));
        }
        user = !user ? await userManager.getUser() : user;
        dispatch({ type: 'INITIALIZED', user });
      } catch (error) {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname,
        );
        dispatch({ type: 'ERROR', error: loginError(error) });
      }
    })();
  }, [userManager, skipSigninCallback, onSigninCallback]);

  // register to userManager events
  useEffect(() => {
    if (!userManager) return undefined;

    const handleUserSignedIn = (): void => {
      captureEvent({
        level: 'log',
        message: 'User signed in',
        extra: {
          navigatorLanguage: window.navigator.language,
          intlLocale: new Intl.DateTimeFormat().resolvedOptions().locale,
        },
      });
    };
    userManager.events.addUserSignedIn(handleUserSignedIn);

    // event UserLoaded (e.g. initial load, silent renew success)
    const handleUserLoaded = (user: User): void => {
      window.history.replaceState({}, document.title, window.location.pathname);
      if (!user?.id_token) {
        dispatch({ type: 'ERROR', error: new Error('Token retrieve failed') });
        return;
      }

      dispatch({ type: 'USER_LOADED', user });
    };
    userManager.events.addUserLoaded(handleUserLoaded);

    // event UserUnloaded (e.g. userManager.removeUser)
    const handleUserUnloaded = (): void => {
      dispatch({ type: 'USER_UNLOADED' });
    };
    userManager.events.addUserUnloaded(handleUserUnloaded);

    // event SilentRenewError (silent renew error)
    const handleSilentRenewError = (error: Error): void => {
      window.history.replaceState({}, document.title, window.location.pathname);
      dispatch({ type: 'ERROR', error });
    };
    userManager.events.addSilentRenewError(handleSilentRenewError);

    return () => {
      userManager.events.removeUserSignedIn(handleUserSignedIn);
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserUnloaded(handleUserUnloaded);
      userManager.events.removeSilentRenewError(handleSilentRenewError);
    };
  }, [userManager]);

  return (
    <OidcContext.Provider
      value={{
        ...state,
        ...signInFunctions,
        ...(!userManager
          ? { isLoading: false, isAuthenticated: false }
          : undefined),
        events: userManager ? userManager.events : undefined,
      }}
    >
      {children}
    </OidcContext.Provider>
  );
};
