import { Logout as LogoutIcon } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';
import type { FC } from 'react';

export const SignOutMenu: FC = () => (
  <>
    <ListItemIcon>
      <LogoutIcon fontSize="small" />
    </ListItemIcon>
    <ListItemText>Sign Out</ListItemText>
  </>
);
