import axios from 'axios';
import type { Event, EventSeries, Group, Organization, Person } from 'core';
import { LRUCache } from 'lru-cache';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import uri from 'uri-tag';

interface CacheItem<T> {
  state: 'found' | 'not-found';
  value: T;
}

interface Cache<T> {
  invalidate(key: string): void;
  get(key: string): Promise<T | undefined>;
}

class ApiCache<T> implements Cache<T> {
  private cache: LRUCache<string, CacheItem<T>>;

  constructor(
    url: (key: string) => string,
    options: LRUCache.Options<string, CacheItem<T>, unknown>,
  ) {
    this.cache = new LRUCache<string, CacheItem<T>>({
      fetchMethod: async (key, staleValue, options) => {
        const { data } = await axios.get<T>(url(key), {
          signal: options.signal,
        });
        // TODO not-found
        return { state: 'found', value: data };
      },
      ...options,
    });
  }

  public invalidate(key: string): void {
    this.cache.delete(key);
  }

  public async get(key: string): Promise<T | undefined> {
    const fetched = await this.cache.fetch(key);
    return fetched?.state === 'found' ? fetched.value : undefined;
  }
}

export class FakeCache<T extends { _id: string }> implements Cache<T> {
  constructor(private readonly items: T[]) {}

  public invalidate(key: string): void {
    // TODO
  }

  public get(key: string): Promise<T | undefined> {
    return Promise.resolve(this.items.find((item) => item._id === key));
  }
}

interface Caches {
  event: Cache<Event.WithCourses>;
  eventSeries: Cache<EventSeries.Type>;
  group: Cache<Group.Type>;
  person: Cache<Person.Type>;
  organization: Cache<Organization.Type>;
}

const cacheContext = createContext<Caches | undefined>(undefined);

export const CacheProvider: FC<PropsWithChildren> = ({ children }) => {
  const caches: Caches = {
    event: new ApiCache((key) => uri`/api/v1/event/${key}`, { max: 1000 }),
    eventSeries: new ApiCache((key) => uri`/api/v1/event-series/${key}`, {
      max: 1000,
    }),
    group: new ApiCache((key) => uri`/api/v1/group/${key}`, { max: 1000 }),
    person: new ApiCache((key) => uri`/api/v1/person/${key}`, { max: 1000 }),
    organization: new ApiCache((key) => uri`/api/v1/organization/${key}`, {
      max: 1000,
    }),
  };
  return (
    <cacheContext.Provider value={caches}>{children}</cacheContext.Provider>
  );
};

export const FakeCacheProvider: FC<
  PropsWithChildren<{ caches: Partial<Caches> }>
> = ({ caches, children }) => {
  const defautlCaches: Caches = {
    event: new FakeCache([]),
    eventSeries: new FakeCache([]),
    group: new FakeCache([]),
    person: new FakeCache([]),
    organization: new FakeCache([]),
  };
  return (
    <cacheContext.Provider value={{ ...defautlCaches, ...caches }}>
      {children}
    </cacheContext.Provider>
  );
};

export const useCache = (): Caches => {
  const caches = useContext(cacheContext);
  if (!caches)
    throw new Error('useCache can only be used inside a CacheProvider');
  return caches;
};
