import { useContext } from 'react';
import { OidcContext } from './oidc-context.js';
import type { OidcContextProps } from './types.js';

export const useOidc = (): OidcContextProps => {
  const context = useContext(OidcContext);

  if (!context) {
    throw new Error(
      'OidcProvider context is undefined, please verify you are calling useOidc() as child of a <OidcProvider> component.',
    );
  }

  return context;
};
