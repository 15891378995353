import type { Person } from 'core';
import { DateTime } from 'luxon';
import { getCountry } from './country.js';

const displayMiles = (): boolean =>
  ['BS', 'BZ', 'KY', 'LR', 'MM', 'US', 'GB', 'TC'].includes(getCountry() ?? '');

const toFixed = (value: number, decimals: number): string =>
  parseFloat(value.toFixed(decimals)).toString();

export const formatDate = (date: Date): string | undefined => {
  const dateTime = DateTime.fromJSDate(date);
  return dateTime.isValid
    ? dateTime.toLocaleString(DateTime.DATE_MED)
    : undefined;
};

export const formatDateTime = (date: Date): string | undefined => {
  const dateTime = DateTime.fromJSDate(date);
  return dateTime.isValid
    ? dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    : undefined;
};

export const formatTime = (date: Date): string | undefined => {
  const dateTime = DateTime.fromJSDate(date);
  return dateTime.isValid
    ? dateTime.toLocaleString(DateTime.TIME_SIMPLE)
    : undefined;
};

export const formatTimeDifferentDay = (date: Date): string | undefined => {
  const dateTime = DateTime.fromJSDate(date);
  return dateTime.isValid
    ? dateTime.toLocaleString(DateTime.DATETIME_MED)
    : undefined;
};

export const formatSeconds = (
  seconds: number | undefined,
): string | undefined => {
  if (seconds === undefined) return undefined;

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const parts: string[] = [`${remainingSeconds}s`, `${minutes}m`];
  if (hours > 0) parts.push(`${hours}h`);

  return parts.reverse().join(' ');
};

export const formatMeters = (
  meters: number | undefined,
): string | undefined => {
  if (meters === undefined) return undefined;

  if (displayMiles()) {
    const yards = meters / 1.09361;

    if (yards < 2000) return `${Math.round(yards)} yd`;
    return `${toFixed(meters / 1760, 3)} mi`;
  }

  if (meters < 2000) return `${Math.round(meters)} m`;
  return `${toFixed(meters / 1000, 3)} km`;
};

export const formatAltitude = (
  meters: number | undefined,
): string | undefined => {
  if (meters === undefined) return undefined;

  if (displayMiles()) {
    const yards = meters / 1.09361;

    return `${Math.round(yards)} yd`;
  }

  return `${meters.toFixed(1)} m`;
};

export const formatSecondsPerMeter = (
  secondsPerMeter: number | undefined,
): string | undefined => {
  if (secondsPerMeter === undefined) return undefined;

  if (displayMiles()) {
    const secondsPerMile = secondsPerMeter * 1609.344;
    const minutes = Math.floor(secondsPerMile / 60);
    const seconds = Math.round(secondsPerMile % 60);

    return `${minutes}:${String(seconds).padStart(2, '0')} /mi`;
  }

  {
    const secondsPerKm = secondsPerMeter * 1000;
    const minutes = Math.floor(secondsPerKm / 60);
    const seconds = Math.round(secondsPerKm % 60);

    return `${minutes}:${String(seconds).padStart(2, '0')} /km`;
  }
};

export const formatName = (person: Person.Type): string => {
  return (
    person.fullName ??
    (person.firstName && person.lastName
      ? `${person.firstName} ${person.lastName}`
      : person.firstName
        ? person.firstName
        : person.lastName
          ? person.lastName
          : person.email
            ? person.email.split('@')[0]
            : '')
  );
};
