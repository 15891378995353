import type { AxiosRequestConfig } from 'axios';
import type { Person, TokenPayload } from 'core';

export interface MaybeRawToken {
  token?: string | null;
}

export namespace TokenType {
  export const ACCESS = 'access';
  export const REFRESH = 'refresh';
}

export namespace TokenSubjectType {
  export const USER = 'user';
}

export interface TokenProviderLogger {
  verbose: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
}

export interface TokenProviderOptions {
  thresholdBeforeExpire: number;
  thresholdBeforeEarlyRefresh?: number;
  retryCount?: number;
  logger?: TokenProviderLogger;
  setAuth: (authenticationState?: AuthenticationState) => void;
}

export interface Token {
  expires: number;
  payload: TokenPayload;
  raw: string;
  refreshExpires?: number;
}

export interface AxiosOptions {
  config?: AxiosRequestConfig;
}

export interface Auth {
  refresh: () => Promise<unknown>;
  signIn: (provider: string) => Promise<void>;
  signOut: () => Promise<void>;
  state: AuthenticationState | undefined;
}

export interface WebOpenIdConfiguration {
  authorization_endpoint: string;
  end_session_endpoint: string;
  token_endpoint: string;
}

export interface OpenIdConfiguration {
  authUrl: string;
  signOutUrl: string;
  tokenEndpoint: string;
}

export enum AuthStatus {
  INITIALIZING = 0,
  INITIALIZED = 1,
  AUTHENTICATED = 2,
  CONFIG_ERROR = 3,
  AUTHENTICATION_FAILED = 4,
  NOT_AUTHENTICATED = 5,
}

export interface UserInfo {
  userId: string;
  hasRole: (...anyOfRole: string[]) => boolean;
}

export type AuthenticationState = BaseAuthenticationState &
  (
    | InitialAuthenticationState
    | InitializedAuthenticationState
    | AuthenticatedState
    | ConfigErrorState
    | AuthenticationFailedState
    | NotAuthenticatedState
  );

type BaseAuthenticationState = {
  loading: boolean;
  error?: Error;
};

export interface InitialAuthenticationState extends BaseAuthenticationState {
  status: AuthStatus.INITIALIZING;
}

export interface InitializedAuthenticationState {
  status: AuthStatus.INITIALIZED;
  accessExpires: Date;
  refreshExpires: Date;
}

export interface AuthenticatedState {
  status: AuthStatus.AUTHENTICATED;
  timestamp: number;
  accessExpires: Date;
  refreshExpires: Date;
  person: Person.Type;
}

export interface ConfigErrorState {
  status: AuthStatus.CONFIG_ERROR;
}

export interface AuthenticationFailedState {
  status: AuthStatus.AUTHENTICATION_FAILED;
  failure: AuthenticationFailure;
}

export interface AuthenticationFailure {
  error: string;
  errorDescription: string;
}

export interface NotAuthenticatedState {
  status: AuthStatus.NOT_AUTHENTICATED;
}
