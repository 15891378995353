import {
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import { Suspense, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './auth/auth-provider.js';
import { RequireAuth } from './components/require-auth/require-auth.js';
import { MiddleSpinner } from './components/spinner/spinner.js';
import { OidcProvider } from './oidc/oidc-context/export.js';
import { providers } from './oidc/providers.js';
import { NotAuthenticatedPage } from './pages/not-authenticated/page.js';
import { CountryProvider } from './providers/country.js';
import { useRouter } from './router.js';
import { baseTheme } from './theme.js';

export const Site: FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () => createTheme(baseTheme(prefersDarkMode)),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <Box component="main" width="100%">
          <OidcProvider providers={providers}>
            <AuthProvider>
              <RequireAuth fallback={<NotAuthenticatedPage />}>
                <CountryProvider>
                  <Suspense fallback={<MiddleSpinner />}>
                    <RouterProvider
                      router={useRouter()}
                      fallbackElement={<MiddleSpinner />}
                    />
                  </Suspense>
                </CountryProvider>
              </RequireAuth>
            </AuthProvider>
          </OidcProvider>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
