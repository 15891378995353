import { ArrowBack as BackIcon, Help as DocsIcon } from '@mui/icons-material';
import type { MenuItemProps, PopoverProps } from '@mui/material';
import {
  AppBar,
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Collapse,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
} from '@mui/material';
import type { Person } from 'core';
import type { FC, MouseEvent } from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { MiddleSpinner } from '../components/spinner/spinner.js';
import { LogoOnPrimary } from '../img/logo-on-primary.js';
import { formatName } from '../utils/format.js';
import type { NavMenuItemProps } from './types.js';

interface Props {
  anchorElUser?: PopoverProps['anchorEl'] | null;
  handleBack: () => void;
  handleCloseUserMenu: () => void;
  handleNavMenuClick: (href: string) => void;
  handleOpenUserMenu: (event: MouseEvent<HTMLElement>) => void;
  navMenuItems: NavMenuItemProps[];
  profile?: Person.Type;
  showBackButton?: boolean;
  smallScreen: boolean;
  userMenuItems?: MenuItemProps[];
}

export const SiteLayoutView: FC<Props> = ({
  anchorElUser,
  handleBack,
  handleCloseUserMenu,
  handleNavMenuClick,
  handleOpenUserMenu,
  navMenuItems,
  profile,
  showBackButton,
  smallScreen,
  userMenuItems,
}) => (
  <Stack direction="column" flex={1}>
    <AppBar
      position="fixed"
      color="primary"
      enableColorOnDark={true}
      sx={{ zIndex: 10000 }}
    >
      <Container maxWidth="md">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', sm: 'none', alignItems: 'center' },
            }}
          >
            <Collapse in={showBackButton} orientation="horizontal">
              <IconButton
                size="large"
                aria-label="navigate back"
                onClick={handleBack}
                color="inherit"
                sx={{ mr: 1 }}
              >
                <BackIcon sx={{ height: '1.7rem', width: '1.7rem' }} />
              </IconButton>
            </Collapse>
            <Link href="#/" underline="none" color="text.primary">
              <LogoOnPrimary sx={{ height: 28 }} />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'flex', alignItems: 'center' },
            }}
          >
            <Collapse in={showBackButton} orientation="horizontal">
              <IconButton
                size="large"
                aria-label="navigate back"
                onClick={handleBack}
                color="inherit"
                sx={{ ml: -1, mr: 1 }}
              >
                <BackIcon sx={{ height: '1.7rem', width: '1.7rem' }} />
              </IconButton>
            </Collapse>
            <Link href="#/" underline="none" color="text.primary">
              <LogoOnPrimary sx={{ height: 32, mr: 3 }} />
            </Link>
            {navMenuItems.map((menuItem) => (
              <Button
                onClick={() => handleNavMenuClick(menuItem.href)}
                key={menuItem.href}
                sx={{ color: 'primary.contrastText', display: 'block' }}
              >
                {menuItem.children}
              </Button>
            ))}
          </Box>

          <Stack direction="row" sx={{ flexGrow: 0 }}>
            {smallScreen ? (
              <Tooltip title="Help">
                <IconButton
                  aria-label="link to help"
                  href="https://docs.otrax.app/"
                  target="_blank"
                >
                  <DocsIcon sx={{ color: 'primary.contrastText' }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Button
                aria-label="link to help"
                href="https://docs.otrax.app/"
                target="_blank"
                startIcon={<DocsIcon />}
                sx={{ color: 'primary.contrastText', display: 'flex' }}
              >
                Help
              </Button>
            )}
            <Tooltip title="User menu">
              <IconButton
                aria-label="user menu"
                aria-controls="menu-user"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                sx={{ p: 0, ml: 2 }}
              >
                <Avatar
                  alt={profile ? formatName(profile) : undefined}
                  src={profile?.picture ?? undefined}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-user"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{ sx: { minWidth: 240 } }}
            >
              {userMenuItems?.map((item, index) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <MenuItem {...item} key={index} />
              ))}
            </Menu>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
    <Toolbar />
    <Box
      component="main"
      display="flex"
      flex={1}
      sx={{ mb: smallScreen ? 7 : 0 }}
    >
      <Suspense fallback={<MiddleSpinner />}>
        <Outlet />
      </Suspense>
    </Box>
    {smallScreen ? (
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }}
        elevation={3}
      >
        <BottomNavigation showLabels>
          {navMenuItems.map((menuItem) => (
            <BottomNavigationAction
              key={menuItem.href}
              icon={<menuItem.icon />}
              label={menuItem.children}
              onClick={() => handleNavMenuClick(menuItem.href)}
            />
          ))}
        </BottomNavigation>
      </Paper>
    ) : null}
  </Stack>
);
