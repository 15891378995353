import type { SxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';

export const LogoOnPrimary: FC<{ sx?: SxProps }> = ({ sx }) => (
  <Box sx={sx}>
    <svg
      height="100%"
      viewBox="0 0 473 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Logo</title>
      <g clipPath="url(#clip0_2_30)">
        <circle cx="64" cy="64" r="56" stroke="white" strokeWidth="16" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M406.053 41.3539C401.012 34.2383 396.225 30.6264 390.762 29.8346L393.056 14C404.843 15.7082 412.993 23.4713 419.109 32.1056C424.13 39.1939 428.482 47.9182 432.603 56.1787C433.425 57.8278 434.239 59.4585 435.047 61.0539C445.485 81.6722 455.293 96.7641 473.424 97.9339L472.394 113.901C444.025 112.07 430.584 87.6624 420.772 68.2806C419.856 66.4712 418.968 64.6964 418.101 62.9608C413.976 54.7081 410.294 47.3414 406.053 41.3539Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M391.535 104.083L459.035 15.083L471.783 24.7516L404.283 113.752L391.535 104.083Z"
          fill="white"
        />
        <path
          d="M136 29.9627V15.8264H210.273V29.9627H181.5V108.917H164.773V29.9627H136Z"
          fill="white"
        />
        <path
          d="M224.591 108.917V15.8264H259.5C266.652 15.8264 272.652 17.0688 277.5 19.5536C282.379 22.0385 286.061 25.5233 288.545 30.0082C291.061 34.4627 292.318 39.6597 292.318 45.5991C292.318 51.5688 291.045 56.7506 288.5 61.1446C285.985 65.5082 282.273 68.887 277.364 71.2809C272.455 73.6446 266.424 74.8264 259.273 74.8264H234.409V60.8264H257C261.182 60.8264 264.606 60.2506 267.273 59.0991C269.939 57.9173 271.909 56.2052 273.182 53.9627C274.485 51.69 275.136 48.9021 275.136 45.5991C275.136 42.2961 274.485 39.4779 273.182 37.1446C271.879 34.7809 269.894 32.993 267.227 31.7809C264.561 30.5385 261.121 29.9173 256.909 29.9173H241.455V108.917H224.591ZM272.682 66.7355L295.727 108.917H276.909L254.273 66.7355H272.682Z"
          fill="white"
        />
        <path
          d="M319.795 108.917H301.795L334.568 15.8264H355.386L388.205 108.917H370.205L345.341 34.9173H344.614L319.795 108.917ZM320.386 72.4173H369.477V85.9627H320.386V72.4173Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_30">
          <rect width="473" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
