import { Statuses } from '@geee-be/core';
import { captureException, startTransaction, type Span } from '@sentry/browser';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { jsonDateParser } from 'json-date-parser';

interface MaybeWithSpan {
  span?: Span;
}

axios.defaults.transformResponse = [
  (response: string) =>
    ['[', '{'].includes(response.charAt(0))
      ? (JSON.parse(response, jsonDateParser) as Record<string, unknown>)
      : response,
];

axios.interceptors.request.use((config) => {
  const span = startTransaction({
    name: config.url ?? '<unknown>',
    op: 'http.request',
  });
  config.headers['sentry-trace'] = span.toTraceparent();
  (config as MaybeWithSpan).span = span;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    const { span } = response.config as MaybeWithSpan;
    span?.setStatus('ok');
    span?.finish();
    return response;
  },
  (error: AxiosError) => {
    if (error.message === 'Network Error') {
      return Promise.reject(error);
    }
    const { config } = error;
    if (
      config?.url !== '/api/v1/token/refresh' ||
      error.status !== Statuses.UNAUTHORIZED
    ) {
      const extra =
        error.status === Statuses.BAD_REQUEST
          ? { data: error.response?.data }
          : {};
      captureException(error, { extra });
    }
    if (config) {
      const { span } = config as MaybeWithSpan;
      span?.setStatus('error');
      span?.finish();
    }
    return Promise.reject(error);
  },
);
