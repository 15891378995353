import { Api as TestIcon } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';
import type { FC } from 'react';

export const TestMenu: FC = () => (
  <>
    <ListItemIcon>
      <TestIcon fontSize="small" />
    </ListItemIcon>
    <ListItemText>Test</ListItemText>
  </>
);
