import { Box, Card, CardContent, Typography } from '@mui/material';
import type { FC } from 'react';

interface Props {
  error?: unknown;
}

export const ErrorPage: FC<Props> = ({ error }) => (
  <Box
    sx={{
      overflow: 'auto',
      mx: 'auto',
    }}
  >
    <Box
      sx={{
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 360,
        minHeight: '100%',
        mx: 'auto',
        p: 2,
      }}
    >
      <Card elevation={6}>
        <CardContent sx={{ m: 2 }}>
          <Typography gutterBottom variant="h5">
            {(error as Error)?.name ?? 'Error'}
          </Typography>
          <Typography color="text.secondary">
            {(error as Error)?.message ?? 'Unknown error'}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  </Box>
);
